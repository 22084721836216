@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{


    .projectContainer
    {
        @apply justify-center items-center text-2xl text-white duration-300
         xs:w-full sm:w-full md:w-fit lg:w-full xl:w-full  p-3
        hover:bg-[#3D3E51] hover:duration-300 
        rounded-xl bg-[#28293E] border-double border-2 border-springrain

        
        
    }
    .project
    {
       
    }
    .projectDetails
    {
        @apply flex flex-col 
    }
    .projectName
    {
        @apply  p-3 uppercase font-bold text-springrain
    }
    .projectDescription
    {
        @apply p-3 text-sm
    }
    .projectTags
    {
       @apply flex flex-row p-3 
    }
    .tags
    {
        @apply flex flex-row flex-wrap justify-center
    }
    .tags span
    {
        @apply text-springrain text-[45%] font-bold ml-2 mb-2
    }
    span.tailwindcss
    {
        @apply bg-blue-300 px-2 text-black rounded-lg h-fit ml-0
    }
    span.reactJS
    {
        @apply bg-red-500 px-2 text-black rounded-lg h-fit
    }
    span.html
    {
        @apply bg-yellow-300 text-black rounded-lg px-2 h-fit
    }
    span.pokemonAPI
    {
        @apply bg-white  text-black rounded-lg px-2 h-fit
    }
    span.chakraUI
    {
        @apply bg-blue-100  text-black rounded-lg px-2 h-fit
    }
    span.contentful
    {
        @apply bg-orange-300  text-black rounded-lg px-2 h-fit
    }
    span.ongoing
    {
        @apply bg-green-200  text-black rounded-lg px-2 h-fit
    }
    
    // xs:bg-yellow-600 sm:bg-orange-700 md:bg-green-700 lg:bg-blue-700 xl:bg-red-600
    
}
