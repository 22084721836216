@tailwind base;
@tailwind components;
@tailwind utilities;

#hi
{
    @apply text-xl my-8 pl-0 font-bold font-merriweather text-[#74B2E1] xs:my-4 xs:text-2xl sm:text-3xl sm:mb-2  md:text-3xl md:my-4 lg:text-4xl lg:mb-2 xl:text-4xl xl:mb-2
}
#myName
{
    @apply font-bold text-springrain font-raleway xs:text-6xl sm:text-6xl
     md:text-7xl lg:text-7xl  xl:text-8xl
}
#whatIdo
{
    @apply my-8 pl-0 font-bold w-3/4 text-[#74B2E1]  text-xl xs:my-4 xs:text-xl sm:text-3xl sm:my-4 sm:mb-8 md:text-3xl md:my-4 lg:text-4xl lg:mt-4 lg:my-8  xl:my-8 font-merriweather 
}
.checkoutmywork{
    @apply flex flex-row w-fit items-center text-base ml-0 font-bold  mt-4 py-2 px-4 rounded-[5px]
     text-white border-4 bg-sunset-100 duration-300 border-sunset-100 hover:bg-sunset-200 hover:border-sunset-200
      hover:text-white md:w-fit md:text-2xl hover:duration-300
}
// #hero
// {
//     background-image: url(../images/cover2.svg);
//     background-repeat: no-repeat;
//     background-size:cover;
//     background-position: right top;
    
// xs:bg-yellow-600 sm:bg-orange-700 md:bg-green-700 lg:to-blue-700 xl:bg-red-600
// }
// @media(max-width:576px) 
// {
//     #hero
//     {
//         background-image: url(../images/cover2.svg);
//         background-repeat: no-repeat;
//         background-size:cover;
//         background-position: top;
        
//           #blurb
//         {
//             backdrop-filter: blur(8px);
//         }
//     }
    
// }