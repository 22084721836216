@tailwind base;
@tailwind components;
@tailwind utilities;
html{
    scroll-behavior:smooth;
}
@layer components{


    .TrunkA
    {
        @apply w-[1200px] h-[1200px]  absolute
        xs:-left-[850px] xs:-top-[550px]
        sm:-left-[850px] sm:-top-[550px]
        md:-left-[700px] md:-top-[550px] 
        lg:-left-[600px] lg:-top-[550px]
        xl:-left-[600px] xl:-top-[550px]
        z-0  pointer-events-none
    }
    .TrunkB
    {
        @apply w-[1200px] h-[1200px]  absolute 
        xs:left-[200px] xs:top-[1100px] 
        sm:left-[300px] sm:top-[1100px] 
        md:left-[500px] md:top-[1100px]
        lg:left-[550px] lg:top-[1100px]
        xl:left-[900px] xl:top-[1100px]
        z-0  pointer-events-none
    }




}
