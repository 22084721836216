div#navbar
{
    

    a
    {
        text-decoration: none;

        img.logo 
        {
             width:75px;
             height:75px;
             border:none;
        }

    }
        

    
}
.hamburger
{
    cursor:pointer;
    width:24px;
    height:24px;
    transition: all 0.25s;
    position: relative;
    

   
}
.hamburger-top, .hamburger-mid, .hamburger-bot
{
    position: absolute;
    width:24px;
    height:2px;
    top:0;
    left:0;
    background: white;
    transform: rotate(0);
    transition: all 0.5s;
    
}
.hamburger-mid
{

    transform: translateY(7px);
}
.hamburger-bot
{
    transform: translateY(14px);
}
.open
{
    transform: rotate(90deg);

}
.open .hamburger-top
{
    transform: rotate(45deg) translateY(6px) translateX(6px);
    
}
.open .hamburger-mid
{
    display:none;
}
.open .hamburger-bot
{
    transform: rotate(-45deg) translateY(6px) translateX(-6px);
    
}