@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Merriweather';
  src: local('Merriweather-Regular.ttf'),url(./fonts/Merriweather-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  src: local('Raleway-Regular.ttf'),url(./fonts/Raleway-Regular.ttf) format('truetype');
}
html
{scroll-behavior:smooth;}
body {

  font-family: 'Raleway', 'Merriweather', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  

}
.background
{
  background-image: url('./images/Background2.svg');
  background-repeat: no-repeat;
  background-size:cover;
  background-position:center center ;
  background-color:black;
  
}
