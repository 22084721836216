@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{

#aboutMe{

   @apply text-[#EF5857] w-full mb-4 text-4xl font-bold font-raleway
    xs:text-left sm:text-left md:text-center lg:text-5xl lg:text-left 
}
#introduction
{
    @apply flex flex-col items-center justify-start 
    xs:flex-col  xs:justify-center xs:items-center xs:space-y-8 xs:space-x-0
    sm:space-y-8 sm:space-x-0 
    md:place-items-center md:space-x-8 md:w-full md:flex-col 
    lg:flex-row lg:w-full lg:space-x-8 
    xl:w-full xl:space-x-8
}


}